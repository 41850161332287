import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "constants/";

export default function NotFound() {
    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <div className="text-[30px] leading-[40px] text-[#000000] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-3">
            {t("404.404")}
            <button
                className="h-[50px] px-6 bg-[#0B365F] text-[14px] leading-[20px] text-white disabled:opacity-50"
                onClick={() => {
                    navigate(ROUTES.HOME);
                }}
            >
                {t("404.ToMain")}
            </button>
        </div>
    );
}
