import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { LayoutModal } from "components";
import axios from "http/axios";

export default function PromisedPaymentModal({
    activeModal,
    setActiveModal,
    product,
    getLicense,
}: {
    activeModal: boolean;
    setActiveModal: (arg: string) => void;
    product: string;
    getLicense: () => void;
}) {
    const { t } = useTranslation();

    const activateGracePeriod = async () => {
        try {
            await axios.post("customer/activate-promised-payment", {
                product: product,
            });
            getLicense();
            setActiveModal("");
        } catch (e: any) {
            console.log(e.response?.data?.message);
        }
    };

    return createPortal(
        <LayoutModal
            active={activeModal}
            closeModal={() => {
                setActiveModal("");
            }}
        >
            <div className="flex flex-col justify-between">
                <h3 className="text-[24px] leading-[50px] text-[#0B365F] mb-7">{t("Promised.Title")}</h3>
                <div className="max-w-[400px] mx-auto">
                    <p className="text-[14px] text-[#656567] leading-[20px]">
                        {t("Promised.Text-1")} <br /> {t("Promised.Text-2")}
                    </p>
                    <div className="flex mt-20 justify-between">
                        <button
                            className="bg-[#5282FF] text-[16px] leading-[26px] w-[167px] h-[44px] text-white"
                            onClick={() => {
                                activateGracePeriod();
                            }}
                        >
                            {t("Buttons.Continue")}
                        </button>
                        <button
                            className="bg-[#5282FF] text-[16px] leading-[26px] w-[167px] h-[44px] text-white"
                            onClick={() => {
                                setActiveModal("");
                            }}
                        >
                            {t("Buttons.Cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </LayoutModal>,
        document.getElementById("portal") as HTMLElement,
    );
}
