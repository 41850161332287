import { useTranslation } from "react-i18next";
import { prodNameByStrIdent } from "utils/prodNameByStrIdent"

interface ILicense {
    id: number;
    lic_num: string;
    occupied_by: string;
    status: string;
    expired_at: string;
}

interface ILicenses {
    acticode: string;
    licenses: ILicense[];
    product: string;
}

export default function License({
    setActiveModal,
    item,
    setProduct,
}: {
    setActiveModal: (arg: string) => void;
    item: ILicenses;
    setProduct: (arg: string) => void;
}) {
    const { t } = useTranslation();

    const status: any = {
        ACTIVE: t("Licenses.ActiveTill"),
        EXPIRED: t("Licenses.Expired"),
        GRACE_PERIOD: t("Licenses.GracePeriod"),
        GRACE_PERIOD_EXPIRED: t("Licenses.GracePeriodExpired"),
    };

    return (
        <div>
            <h2 className="text-[20px] leading-[26px] text-[#656567] font-bold underline mb-[1px]">
                {t("Licenses." + prodNameByStrIdent(item.product))}
            </h2>
            <div className="flex flex-col gap-[1px] text-[16px] leading-[29px] text-[#656567]">
                {item.licenses.map((item) => (
                    <p key={item.id}>
                        {item.lic_num}.{" "}
                        {item.occupied_by && (
                            <span>
                                {t("Licenses.Occupied")}: {item.occupied_by}.
                            </span>
                        )}{" "}
                        {status[item.status]} {item.expired_at.split("T")[0]}
                    </p>
                ))}
                <p>
                    {t("Licenses.ActivationCode")}: <b>{item.acticode}</b>
                </p>
                <div className="flex gap-[30px]">
                    <button
                        className="border border-[#0B365F] text-[16px] leading-[22px] text-[#0B365F] py-[2px] px-[16.5px] rounded-[2px] duration-200 hover:text-white hover:bg-[#0B365F]"
                        onClick={() => {
                            setActiveModal("prolongation");
                            setProduct(item.product);
                        }}
                    >
                        {t("Licenses.Extend")}
                    </button>
                    {item.licenses.some((o) => o.status === "EXPIRED") && (
                        <button
                            className="border border-[#0B365F] text-[16px] leading-[22px] text-[#0B365F] py-[2px] px-[16.5px] rounded-[2px] hover:text-white hover:bg-[#0B365F]"
                            onClick={() => {
                                setActiveModal("promisedPayment");
                                setProduct(item.product);
                            }}
                        >
                            {t("Licenses.ActivateGracePeriod")}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
