import { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import axios from "http/axios";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import { ROUTES } from "constants/";

export default function Header({ setAuth }: { setAuth: (arg: boolean) => void }) {
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    const language = searchParams.get("language") || "";

    const navigate = useNavigate();

    const [activeLanguage, setActiveLanguage] = useState("");

    const { i18n } = useTranslation();

    const languages = ["en", "ru"];

    const changeLanguage = (language: string) => {
        navigate({
            pathname,
            search: `?language=${language}`,
        });
        setActiveLanguage(language);
        i18n.changeLanguage(language);
    };

    const logout = async () => {
        try {
            await axios.get(`customer/logout`);
            setAuth(false);
            navigate(ROUTES.HOME);
        } catch (e: any) {
            console.log(e.response?.data?.message);
        }
    };

    useEffect(() => {
        languages.includes(language) ? changeLanguage(language) : changeLanguage(cookies.get("i18next") || "en");
    }, []);

    return (
        <div className="bg-white">
            <div className="container">
                <div className="pt-[31.5px] pb-10 bg-white px-6 flex justify-between relative">
                    <a href={t("Header.HomeLink")}>
                    {activeLanguage === "ru" ? <img src="images/logo.svg" alt="" /> : <img src="images/logo-2.svg" alt="" />}
                    </a>
                    <div className="group uppercase text-[#656567] text-[12px] leading-[18px] flex items-center gap-[11px] justify-between rounded-[10px] border border-[#E7E7E7] px-3 h-[38px] relative cursor-pointer">
                        {activeLanguage}
                        <svg className="h-[10px] w-3 group-hover:rotate-180 duration-200" viewBox="0 0 10 6">
                            <path d="M-2.18557e-07 0.5L5 5.5L10 0.5L-2.18557e-07 0.5Z" fill="#656567" />
                        </svg>

                        <div
                            className="absolute overflow-hidden uppercase duration-200 top-full w-full left-0 rounded-[10px] border border-[#E7E7E7] bg-white mt-2
                            invisible opacity-0 group-hover:opacity-100 group-hover:visible z-10"
                        >
                            <ul>
                                {languages.map((item, index) => (
                                    <li
                                        key={index}
                                        className={classNames(
                                            "px-3 hover:bg-[#A6B0B9] hover:text-white w-full duration-200 h-[38px] flex items-center",
                                            activeLanguage === item && "hidden",
                                        )}
                                        onClick={() => {
                                            changeLanguage(item);
                                        }}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {pathname !== ROUTES.HOME && (
                        <div className="group absolute text-[14px] leading-[20px] text-[#333333] bottom-2 right-6 cursor-pointer">
                            {t("Header.Account")}: {cookies.get("email")}
                            <ul
                                className="absolute duration-200 rounded-[10px] border border-[#E7E7E7] bg-white px-3 py-2 right-0 w-[150px] mt-2
                                invisible opacity-0 group-hover:opacity-100 group-hover:visible"
                            >
                                <li
                                    onClick={() => {
                                        logout();
                                    }}
                                >
                                    {t("Header.Logout")}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
