import classNames from "classnames";

const LayoutModal = ({ children, closeModal, className, active }: { children?: JSX.Element; closeModal: () => void; className?: string; active: boolean }) => {
    return (
        <div
            className={classNames(
                "fixed top-0 w-full h-full bg-[rgba(176,176,176,0.78)] overflow-auto",
                active ? "visible opacity-100" : "invisible opacity-0",
            )}
            onClick={() => {
                closeModal();
                document.body.classList.remove("lock");
            }}
        >
            <div className={classNames("py-5 px-5 flex items-center justify-center min-h-full")}>
                <div
                    className={classNames("bg-white relative w-[560px] p-[43px_80px_57px_51px] rounded-[10px]", className)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}

                    <button
                        className="absolute top-[29px] right-[42px]"
                        onClick={() => {
                            closeModal();
                            document.body.classList.remove("lock");
                        }}
                    >
                        <svg width="18" height="17">
                            <line y1="-0.5" x2="22.4348" y2="-0.5" transform="matrix(0.700986 -0.713175 0.700986 0.713175 1.27344 16.9999)" stroke="#5282FF" />
                            <line y1="-0.5" x2="22.4348" y2="-0.5" transform="matrix(0.700986 0.713175 -0.700986 0.713175 1 1.00021)" stroke="#5282FF" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LayoutModal;
