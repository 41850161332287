import { Header, Footer } from "components";

export default function Layout({ children, setAuth }: { children: JSX.Element; setAuth: (arg: boolean) => void }) {
    return (
        <>
            <Header setAuth={setAuth} />
            <main className="flex bg-[#F6F6F4]">
                <div className="container flex flex-col flex-auto">{children}</div>
            </main>
            <Footer />
        </>
    );
}
