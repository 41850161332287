import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <div className="bg-white">
            <div className="container">
                <div className="px-[70px] pt-[64px] pb-[46px] bg-white flex justify-between">
                    <div>
                        <p className="text-[#656567] text-[16px] leading-[26px] mb-6">{t("Footer.Copyright")}</p>
                        <div className="text-[14px] leading-[20px] text-[#656567] mb-1 flex flex-col gap-1">
                            <a href={t("Footer.PrivacyPolicyLink")}>{t("Footer.PrivacyPolicy")}</a>
                            <a href={t("Footer.TermsOfUseLink")}>{t("Footer.TermsOfUse")}</a>
                        </div>
                    </div>
                    <div className="min-w-[340px]">
                        <p className="font-['Ubuntu'] font-medium text-[20px] leading-[28px] text-[#333333] mb-1">{t("Footer.Contacts")}</p>
                        <div className="text-[14px] text-[#656567] leading-[20px] flex flex-col gap-1">
                            <a href="mailto:info@kladera.com">info@kladera.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
