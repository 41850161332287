const ProductsMap = new Map<string, string>();

    function addProductIdentifiers(product: string, identifiers: string[]): void {
        identifiers.forEach(identifier => {
            ProductsMap.set(identifier.toLowerCase(), product);
        });
    }

    addProductIdentifiers('KladEra',['KTL', 'E']);
    addProductIdentifiers('RoofProfy',['ROL', 'K']);

export function prodNameByStrIdent(strIdent: string): string{
    return ProductsMap.get(strIdent.toLowerCase()) || 'Unknown';
}