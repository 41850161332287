import axios from "axios";
import cookies from "js-cookie";
import globalRouter from "globalRouter";
import { ROUTES } from "constants/";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        if ((error.response.status === 401 || error.response.status === 419) && globalRouter.navigate) {
            cookies.remove("XSRF-TOKEN");
            globalRouter.navigate(ROUTES.HOME);
        }
        throw error;
    },
);

export default instance;
