import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { Layout } from "components";
import cookies from "js-cookie";
import { ROUTES } from "constants/";
import axios from "http/axios";

export default function Main({ auth, setAuth }: { auth: boolean; setAuth: (arg: boolean) => void }) {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [resend, setResend] = useState(60);

    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const onConfirmation = async () => {
        try {
            await axios.post(`customer/verify-otp`, {
                otp: value,
                //email: email
            });
            setAuth(true);
            navigate(ROUTES.LICENSES);
        } catch (e: any) {
            setError(e.response?.data?.message);
        }
    };

    const timer = () => {
        setResend(60);
        const intervalId = setInterval(() => {
            resend < 0 ? clearInterval(intervalId) : setResend((prev) => prev - 1);
        }, 1000);
    };

    const onSubmit = async () => {
        try {
            setError("");
            !confirmation && (await axios.get(`sanctum/csrf-cookie`));
            //reg.exec(value); //use it later for email mask check
            const response = await axios.post(
                "customer/generate-otp",
                {email: value, lang: i18n.language}
            );
            reg.exec(value) && cookies.set("email", value);
            setEmail(value);
            setConfirmation(true);
            setValue("");
            timer();
            console.log(response);
        } catch (e: any) {
            console.log(e);
            setError(e.response?.data?.message || e.response?.data?.error);
        }
    };

    const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
        <>
            {!auth ? (
                <Layout setAuth={setAuth}>
                    <div className="bg-[#F6F6F4] flex-auto px-[50px] py-[14px]">
                        <div className="flex flex-col gap-[11px] items-start">
                            <h1 className="font-['Ubuntu'] text-[30px] leading-[40px] font-light text-[#000000]">{t("Main.Title")}</h1>
                            <p className="text-[16px] leading-[26px] text-[#656567] max-w-[652px]">{confirmation ? t("Main.Text-3", {email}) : t("Main.Text-1")}</p>
                            {!confirmation && <p className="text-[16px] leading-[26px] text-[#656567] max-w-[652px]">{t("Main.Text-2")}</p>}
                            <div className="mt-1 flex gap-5">
                                <input
                                    type="text"
                                    className="border border-[#BDBDBD] bg-white h-[50px] w-[421px] placeholder:text-[14px] placeholder:text-[#656567] placeholder:text-center placeholder:leading-[20px] px-4"
                                    placeholder={confirmation ? t("Main.Input-2") : t("Main.Input")}
                                    value={value}
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                    }}
                                />
                                <button
                                    className="h-[50px] px-6 bg-[#0B365F] text-[14px] leading-[20px] text-white disabled:opacity-50"
                                    disabled={!value}
                                    onClick={() => {
                                        confirmation ? onConfirmation() : onSubmit();
                                    }}
                                >
                                    {confirmation ? t("Main.Button-3") : t("Main.Button")}
                                </button>
                            </div>
                            {error && <p className="text-[red] text-[16px] leading-[26px]">{error}</p>}
                            {confirmation && resend < 0 ? (
                                <button
                                    className="mt-1 text-[12px] leading-[18px] text-[#5282FF] border-b border-[#5282FF]"
                                    onClick={() => {
                                        setValue('');
                                        setConfirmation(false);
                                    }}
                                >
                                    {t("Main.Button-2")}
                                </button>
                            ) : (
                                confirmation && (
                                    <p className="text-[16px] leading-[26px] text-[#656567]">
                                        {t("Main.Resend")} {resend} {t("Main.Seconds")}
                                    </p>
                                )
                            )}
                        </div>
                    </div>
                </Layout>
            ) : (
                <Navigate to={ROUTES.LICENSES} replace={true} />
            )}
        </>
    );
}
