import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, License, PromisedPaymentModal, ProlongationModal } from "components";
import { ROUTES } from "constants/";
import axios from "http/axios";

export default function Main({ auth, setAuth }: { auth: boolean; setAuth: (arg: boolean) => void }) {
    const [activeModal, setActiveModal] = useState("");
    const [license, setLicense] = useState([]);
    const [product, setProduct] = useState("");

    const { t } = useTranslation();

    const getLicense = async () => {
        try {
            const repsonse = await axios.get("/customer/acticodes");
            setLicense(repsonse.data.acticodes);
        } catch (e: any) {}
    };

    useEffect(() => {
        getLicense();
    }, []);

    return (
        <>
            {!auth ? (
                <Navigate to={ROUTES.HOME} replace={true} />
            ) : (
                <>
                    <Layout setAuth={setAuth}>
                        <div className="bg-[#F6F6F4] flex-auto px-[50px] py-[14px]">
                            <h1 className="font-['Ubuntu'] text-[30px] leading-[40px] font-light text-[#000000] mb-[17px]">{t("Licenses.Title")}</h1>
                            <div className="flex flex-col gap-[13px] items-start">
                                {license?.map((item, index) => (
                                    <License key={index} setActiveModal={setActiveModal} item={item} setProduct={setProduct} />
                                ))}
                            </div>
                        </div>
                    </Layout>

                    <PromisedPaymentModal
                        activeModal={activeModal === "promisedPayment"}
                        setActiveModal={setActiveModal}
                        product={product}
                        getLicense={getLicense}
                    />

                    <ProlongationModal 
                    activeModal={activeModal === "prolongation"} 
                    setActiveModal={setActiveModal} 
                    product={product}
                    />
                </>
            )}
        </>
    );
}
