import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { LayoutModal } from "components";
import { prodNameByStrIdent } from "utils/prodNameByStrIdent"
    
export default function ProlongationModal({ 
    activeModal, 
    setActiveModal,
    product 
}: { 
    activeModal: boolean; 
    setActiveModal: (arg: string) => void;
    product: string
}) {
    const { t } = useTranslation();

    return createPortal(
        <LayoutModal
            active={activeModal}
            closeModal={() => {
                setActiveModal("");
            }}
        >
            <div className="flex flex-col justify-between">
                <h3 className="text-[24px] leading-[50px] text-[#0B365F] mb-7">{t("Prolongation.Title")}</h3>
                <div className="max-w-[400px] mx-auto">
                    <div className="text-[14px] text-[#656567] leading-[20px]">
                        <p>{t("Prolongation.Text-1")}</p>
                        <ul className="pl-1">
                            <li>{t("Prolongation.Lists.1")}</li>
                            <li>{t("Prolongation.Lists.2")}</li>
                            <li>{t("Prolongation.Lists.3")}</li>
                        </ul>
                        <p>{t("Prolongation.Text-2")}</p>
                    </div>
                    <div className="flex mt-20 justify-between">
                        <button
                            className="bg-[#5282FF] text-[16px] leading-[26px] w-[167px] h-[44px] text-white"
                            onClick={() => {
                                window.location.href = t("Prolongation.Link." + prodNameByStrIdent(product));
                            }}
                        >
                            {t("Buttons.Continue")}
                        </button>
                        <button
                            className="bg-[#5282FF] text-[16px] leading-[26px] w-[167px] h-[44px] text-white"
                            onClick={() => {
                                setActiveModal("");
                            }}
                        >
                            {t("Buttons.Cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </LayoutModal>,
        document.getElementById("portal") as HTMLElement,
    );
}
