import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { Licenses, Main, NotFound } from "pages";
import axios from "http/axios";

function App() {
    const [auth, setAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                await axios.get(`customer/authenticated`);
                setAuth(true);
            } catch (e: any) {
                console.log(e.response?.data?.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Router>
            {!loading && (
                <Routes>
                    <Route path={`${ROUTES.HOME}`} element={<Main auth={auth} setAuth={setAuth} />} />
                    <Route path={`${ROUTES.LICENSES}`} element={<Licenses auth={auth} setAuth={setAuth} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            )}
        </Router>
    );
}

export default App;
